<template>
    <div class="">
        <div v-if="selectedGroupFlag && !selectedTariffFlag" @click="selectedGroupFlag = false" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-[#4A4E69]">
                Назад
            </div>
        </div>
        <div v-else-if="selectedTariffFlag" @click="selectedTariffFlag = false" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-[#4A4E69]">
                Назад
            </div>
        </div>
        <div v-else @click="$router.push('/')" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-[#4A4E69]">
                Назад
            </div>
        </div>
        <div v-if="!(selectedGroupFlag && !selectedTariffFlag)" class="text-gray-emb text-center font-medium mb-[10px] text-[16px]">АБОНЕМЕНТИ</div>
        <div v-if="!(selectedGroupFlag && !selectedTariffFlag)" class="h-px w-full bg-gray-emb rounded-full mb-[100px]"></div>

        <SquircleComponent 
            v-if="!selectedGroupFlag && !selectedTariffFlag" 
            v-for="group in allTariffsGroup" 
            @click="selectGroup(group)" 
            class="mb-[26px] mx-auto"
            :smooth="60" 
            :radius="52" 
            :width="306" 
            :height="120" 
            :outlineWidth="1"
        >

            <div class="bg-gray-emb relative h-[57px] w-full flex flex-col items-center text-white justify-center">
                <div 
                    class="text-[20px] font-semibold"
                >{{ group.group_name }}</div>
                <div class="text-[10px]">
                    {{group.tariffs[0].tariff_name}}
                </div>
            </div>
            <div 
                class="text-[10px] text-start h-full w-full px-[26px] pb-[16px] pt-[12px] leading-[14px] mx-auto  text-gray-emb"
                :style="[`border-color: ${group.tariffs[0].tariff_color}; background-color: ${group.tariffs[0].tariff_color}`]"        
            >
                {{ group.tariffs[0].tariff_description }}
            </div>
        </SquircleComponent>
        
        <div v-if="selectedGroupFlag && !selectedTariffFlag && selectedGroup?.group_name != 'PERSONAL'" class="flex flex-col justify-center items-center px-[10px] mt-[24px]">
            <TariffCard v-for="tariff in selectedGroup.tariffs" @click="selectTariff(tariff)" :tariff="tariff" class="mb-[8px]" />
        </div>

        <div v-if="selectedGroup?.group_name == 'PERSONAL' && selectedGroupFlag" class="flex flex-col mt-[100px] justify-center items-center w-full px-[10px] h-full">
            <!-- <TariffCard :tariff="allTariffsGroup[0].tariffs[0]" class="mb-[8px] mx-auto" /> -->
            <img src="@/mobileApp/assets/bookmark_none.svg" alt="" class="w-[60px]">
            <div class="text-gray-emb text-center font-medium mb-[10px] text-[16px]">
                Наразі продаж індивідуальних<br>абонементів не доступний
            </div>
        </div>

        <div v-if="selectedTariffFlag" class="h-[73vh] flex flex-col items-center">
            <TariffCard :tariff="selectedTariff" class="mb-[16px] mt-[10vh]" />
            <SquircleComponent 
                v-if="selectedTariffFlag" 
                @click="createTransaction"
                class="bg-green-emb border-[1px] border-green-emb items-center px-[13px] absolute bottom-[0] text-gray-emb flex mx-auto justify-center mb-[16px]"
                :smooth="60" 
                :radius="22" 
                :width="screenWidth * 0.92" 
                :height="44" 
                :outlineWidth="1"    
            >
                <div class="w-full text-sm text-center">Сплатити</div>
            </SquircleComponent>
            <!-- <button v-if="selectedTariffFlag" @click="createTransaction" class="bg-green-emb rounded-[9px] text-gray-emb px-3 absolute bottom-[0] py-3 w-[92%] text-base font-semibold flex items-center align-middle justify-between mx-auto">
                <div class="w-full text-sm">Сплатити</div>
            </button> -->
        </div>
    </div>
</template>

<script>
    import { onMounted, ref, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    import { createWayforpayTransaction, getAllTariffGroups, getAllGroupAndItsTariffs } from '@/mobileApp/services/apiRequests'
    import TariffCard from '@/mobileApp/components/Tariffs/TariffCard.vue'
    import SquircleComponent from '@/mobileApp/components/SquircleComponent.vue'

    export default {
        name: 'payment-view',
        
        setup() {
            const store = useMainStore()
            const router = useRouter()
            const amount = ref(null)

            const allTariffsGroup = ref([])

            const selectedGroup = ref(null)
            const selectedGroupFlag = ref(false)

            const selectedTariff = ref(null)
            const selectedTariffFlag = ref(false)

            const screenWidth = ref(window.innerWidth)

            const selectTariff = (tariff) => {
                selectedTariff.value = tariff
                selectedTariffFlag.value = true
            }

            const selectGroup = (group) => {
                selectedGroup.value = group
                selectedGroupFlag.value = true
            } 

            onMounted(async () => {
                allTariffsGroup.value = (await getAllGroupAndItsTariffs()).data
                console.log('allTariffsGroup.value ---------- ', allTariffsGroup.value)

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            const createTransaction = async () => {
                const res = await createWayforpayTransaction(
                    selectedTariff.value.tariff_price,
                    store.student.student_id,
                    store.student.name,
                    store.student.surname,
                    store.student.email,
                    store.student.phone,
                    selectedTariff.value.tariff_id,
                    selectedTariff.value.tariff_name
                )
                window.open(res.data.payment.url, '_parent')
                router.push('/payment-redirect')
                // alert('Платіжна система не підключена. Оплачувати наразі неможливо')
            }

            const createDebtTransaction = async () => {
                // const debt = store.studentBalance * -1
                // const res = await createWayforpayTransaction(
                //     debt,
                //     store.student.student_id,
                //     store.student.name,
                //     store.student.surname,
                //     store.student.email,
                //     store.student.phone
                // )
                // console.log('res.data ---------- ', res.data)
                // window.open(res.data.payment.url, '_parent')
                // router.push('/payment-redirect')
                alert('Платіжна система не підключена. Оплачувати наразі неможливо')
            }

            return {
                allTariffsGroup,
                selectGroup,
                selectedGroup,
                selectedGroupFlag,
                selectTariff,
                selectedTariff,
                selectedTariffFlag,
                createTransaction,
                screenWidth
            }
        },

        components: {
            TariffCard,
            SquircleComponent
        }
    }
</script>
